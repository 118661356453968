import getEngineIds from "Clutch/Utilities/CustomerProjectUtilities/getEngineIds";
import getMatchingProject from "Clutch/Utilities/CustomerProjectUtilities/getMatchingProject"

export const showAddToGarage = (customerProjects, fitmentDetails) => {
  if (!fitmentDetails?.makeId && !fitmentDetails?.raceTypeId && !fitmentDetails?.engineManufacturerId) 
    return false;
  
  return !getMatchingProject(fitmentDetails, customerProjects);
};

export const showUpdateCustomerProject = (customerProjects, fitmentDetails) => {
  if (!customerProjects || (!fitmentDetails?.makeId && !fitmentDetails?.raceTypeId && !fitmentDetails?.engineManufacturerId)) 
    return false;

  if (
    ((fitmentDetails.year && fitmentDetails.makeId && fitmentDetails.modelId) ||
      fitmentDetails.raceTypeId) &&
    fitmentDetails.engineManufacturerId
  ) {
    const matchingProject = customerProjects?.find(
      (project) =>
        project.year == fitmentDetails?.year &&
        project.makeId == fitmentDetails?.makeId &&
        project.modelId == fitmentDetails?.modelId &&
        project.raceTypeId == fitmentDetails?.raceTypeId &&
        project.engineManufacturerId === null
    );
    const existingUpdatedProject = getMatchingProject(fitmentDetails, customerProjects)
    if (!existingUpdatedProject && matchingProject) 
      return matchingProject
    return false
  }
};

export const addToGarage = async (
  fitmentDetails,
  setLoading,
  selectCustomerProject,
  addCustomerProject,
  pushNotification,
  vehicleName
) => {
  try {
    setLoading(true);
    const engineIds = getEngineIds(
      !!fitmentDetails?.engineManufacturerId,
      fitmentDetails
    );

    const response = await addCustomerProject({
      year: fitmentDetails?.year,
      makeId: fitmentDetails?.makeId,
      modelId: fitmentDetails?.modelId,
      raceTypeId: fitmentDetails?.raceTypeId,
      engineIds: engineIds,
    });

    const projectIds = {
      year: fitmentDetails?.year,
      makeId: fitmentDetails?.makeId,
      modelId: fitmentDetails?.modelId,
      raceTypeId: fitmentDetails?.raceTypeId,
      engineManufacturerId: engineIds?.engineManufacturerId,
      engineMakeId: engineIds?.engineMakeId,
      engineSeriesId: engineIds?.engineSeriesId,
      engineConfigurationId: engineIds?.engineConfigurationId,
      engineVersionId: engineIds?.engineVersionId,
      engineQualifierId: engineIds?.engineQualifierId,
      engineDisplacementId: engineIds?.engineDisplacementId,
      engineCodeId: engineIds?.engineCodeId,
      engineCamshaftTypeId: engineIds?.engineCamshaftTypeId,
      engineFuelSystemId: engineIds?.engineFuelSystemId
    }

    const selectedProject = getMatchingProject(projectIds, response.projects)

    await selectCustomerProject(selectedProject.projectId, null);

    pushNotification(
      `${vehicleName} added to your garage!`,
      "positive",
      5000,
      null,
      {
        intent: "positive",
        icon: "check",
      }
    );
    setLoading(false);
  } catch (err) {
    setLoading(false);
    pushNotification(`Something went wrong, please try that again`, "negative");
  }
};

export const updateCustomerProject = async (
  fitmentDetails,
  projectToUpdate,
  setLoading,
  selectCustomerProject,
  updateCustomerProjectData,
  pushNotification,
  vehicleName
) => {
  try {
    setLoading(true);
    const engineIds = getEngineIds(true, fitmentDetails);

    const response = await updateCustomerProjectData({
      year: projectToUpdate.year,
      makeId: projectToUpdate.makeId,
      modelId: projectToUpdate.modelId,
      raceTypeId: projectToUpdate.raceTypeId,
      engineIds,
      vehicleBaseId: projectToUpdate.vehicleBaseId,
      customerProjectId: projectToUpdate.projectId,
    });

    if (!response || !response.success) {
      throw "updateCustomerProjectData failed";
    }

    await selectCustomerProject(projectToUpdate.projectId, null);

    pushNotification(
      `${vehicleName} updated in your garage!`,
      "positive",
      5000,
      null,
      {
        intent: "positive",
        icon: "check",
      }
    );
    setLoading(false);
  } catch (err) {
    setLoading(false);
    pushNotification(`Something went wrong, please try that again`, "negative");
  }
};
