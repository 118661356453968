import React, { useContext, useState } from "react";

import TextLink from "Clutch/Atoms/TextLink";
import CoveringSpinner from "Clutch/Atoms/CoveringSpinner";
import Svg from "Clutch/Atoms/Svg";

import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import {
  showAddToGarage,
  addToGarage,
  showUpdateCustomerProject,
  updateCustomerProject,
} from "/Utilities/garageUtilities";
import useProductResultPage from "../../../hooks/useProductResultPage";
import { NotificationContext } from "Clutch/Contexts/NotificationContext/NotificationContext";
import useVehicleSidebar from "../../../hooks/useVehicleSidebar";
import { useFeatureFlag } from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import getFitmentDetailsFromSelectedFacets from "Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets"

import styles from "./garageControls.module.scss";

const GarageControls = () => {
  const [addingToGarage, setAddingToGarage] = useState(false);

  const customerProjects = useVehicleStore((x) => x.context.customerProjects);
  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag")
  const engineFitmentChecksEnabled = useFeatureFlag(
    "PRP_New_Engine_Fitment_Checks"
  );
  const selectedFacetOptions = useProductResultPage(x => x.selectedFacetOptions)
  const engineFitmentPageTitle = useProductResultPage(x => x.engineFitmentPageTitle)
  const fitmentDetailsFromSelectedFacets = getFitmentDetailsFromSelectedFacets(selectedFacetOptions)
  const fitmentDetailsFromFitmentFacet = (selectedFacetOptions || []).find(x => x.fitmentDetails != null)?.fitmentDetails
  const fitmentDetails = engineFitmentChecksEnabled ? fitmentDetailsFromSelectedFacets : fitmentDetailsFromFitmentFacet || {}
  const { vehicleName } = useVehicleSidebar(
    selectedFacetOptions,
    fitmentDetails,
    engineFitmentPageTitle
  );

  const { PushNotification } = useContext(NotificationContext);
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject);
  const selectCustomerProject = useVehicleStore(
    (state) => state.selectCustomerProject
  );
  const updateCustomerProjectData = useVehicleStore((x) => x.updateCustomerProjectData)

  const canAdd = showAddToGarage(customerProjects, fitmentDetails);
  const canUpdate = showUpdateCustomerProject(customerProjects, fitmentDetails);

  if (!canAdd && !canUpdate) {
    return false;
  }

  return (
    <CoveringSpinner isLoading={addingToGarage}>
      {canUpdate && (
        <TextLink
          data-testid="updateVehicleInGarage"
          className={styles.vehicleName}
          intent={"action"}
          size={0.875}
          onClick={() =>
            updateCustomerProject(
              fitmentDetails,
              canUpdate,
              setAddingToGarage,
              selectCustomerProject,
              updateCustomerProjectData,
              PushNotification,
              vehicleName
            )
          }
          segmentEvent={createClickedSegmentEvent('UpdateProject')}
        >
          <Svg icon={"add"} className={styles.svg} size={0.875} />
          Update Garage
        </TextLink>
      )}
      {!canUpdate && canAdd && (
        <TextLink
          data-testid="addVehicleToGarage"
          className={styles.vehicleName}
          intent={"action"}
          size={0.875}
          onClick={() =>
            addToGarage(
              fitmentDetails,
              setAddingToGarage,
              selectCustomerProject,
              addCustomerProject,
              PushNotification,
              vehicleName
            )
          }
          segmentEvent={createClickedSegmentEvent('AddProject')}
        >
          <Svg icon={"add"} className={styles.svg} size={0.875} />
          Add To My Garage
        </TextLink>
      )}
    </CoveringSpinner>
  );
};

export default GarageControls;
