import React, {useState} from 'react';
import Area from 'Clutch/Molecules/Area';
import Typography from 'Clutch/Atoms/Typography';
import Popover from 'Clutch/Components/Popover';
import styles from './isFollowedAndIndexed.module.scss';

const IsFollowedAndIndexed = ({isFollow, isIndex}) => {
    const [isFollowOpen, setIsFollowOpen] = useState(false);
    const [isIndexOpen, setIsIndexOpen] = useState(false);

    const handleFollowMouseEnter = () => {
        setIsFollowOpen(true);
    };

    const handleFollowMouseLeave = () => {
        setIsFollowOpen(false);
    };

    const handleIndexMouseEnter = () => {
        setIsIndexOpen(true);
    };

    const handleIndexMouseLeave = () => {
        setIsIndexOpen(false);
    };

    const followAreaContent = (
        <Area
            className={styles.rule}
            title="Follow Status"
            brand={isFollow ? 'primary' : undefined}
        >
            <Typography size={0.75}>
                {`Follow`}
            </Typography>
        </Area>
    );

    const indexAreaContent = (
        <Area
            className={styles.rule}
            title="Index Status"
            brand={isIndex ? 'primary' : undefined}
        >
            <Typography size={0.75}>
                {`Index`}
            </Typography>
        </Area>
    );

    return (
        <div className={styles.container}>
            <div
                onMouseEnter={handleFollowMouseEnter}
                onMouseLeave={handleFollowMouseLeave}
            >
                <Popover
                    content={
                        <div className={styles.popoverContent}>
                            {followAreaContent}
                        </div>
                    }
                    isOpen={isFollowOpen}
                >
                    {followAreaContent}
                </Popover>
            </div>
            <div
                onMouseEnter={handleIndexMouseEnter}
                onMouseLeave={handleIndexMouseLeave}
            >
                <Popover
                    content={
                        <div className={styles.popoverContent}>
                            {indexAreaContent}
                        </div>
                    }
                    isOpen={isIndexOpen}
                >
                    {indexAreaContent}
                </Popover>
            </div>
        </div>
    );
};

export default IsFollowedAndIndexed;